<template>
  <div class="box-cover">
    <div style="display: flex; flex-direction: column; align-items: center;">
      <div class="title" style="margin-bottom: 80px;">连接中聚，加速创造未来</div>
<!--      <div class="more">查看更多</div>-->
    </div>
    <el-card class="card-cover">
      <div class="vidioCls">
        <video style="width: 100%; height: 100%;" ref="videodemo"  muted controls :src="listContent[active] && listContent[active].source_url"></video>
      </div>
    </el-card>
    <div class="tabs-btn">
      <el-card :class="active == index ? 'min-card active' : 'min-card'" v-for="(item,index) in listContent" :key="index"  @click.native="handleBtnClick(index)">
          <span class="index">{{item.title.rendered}}</span>
          <span class="content" v-html="item.caption.rendered"></span>
      </el-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
 export default  {
   data() {
     return {
       active: 0,
       listContent: [
       ],
       playFlag: true
     }
   },
   computed: {
   },
   created() {
     const self = this
     self.listContent = []
     axios.get('http://143.64.40.251/index.php/wp-json/wp/v2/media').then(res => {
       res.data.forEach(item => {
         if(['mp4','avi','wmv','rm','ram'].includes(item.source_url.split('.').pop())) {
           self.listContent.push(item)
         }
       })
       if(self.listContent.length) {
         self.handleBtnClick(0)
       }
     })
   },
   methods: {
     async handleBtnClick(index) {
         this.active = index
         this.playPause()
     },
     playPause() {
       var video  = this.$refs.videodemo
       if(video !== null) {
           video.pause()
           video.currentTime = 0;
           let playPromise = video.play()
           if(playPromise) {
             playPromise.then(()=> {
               setTimeout(()=> {},video.duration*1000);
               // eslint-disable-next-line no-unused-vars
             }).catch((e)=> {
               this.playPause()
             })
           }
       }
     },
   }
 }
</script>

<style scoped>
.tabs-btn {
  display: flex;
  flex-direction: row;
  margin-top: -48px;
}
.min-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 318px;
  height: 176px;
  border-radius: 24px;
  z-index: 999;
  backdrop-filter: blur(20px);
  background: rgba(255,255,255,0.7);
  box-shadow: 4px 4px 16px 0px rgba(153,153,153,0.2);
}
.min-card:hover {
  cursor: pointer;
}
.min-card:first-child {
  margin-left: 6px;
}
.min-card:last-child {
  margin-right: 6px;
}
.box-cover {
  margin: 0 auto;
  width: 1320px;
}
.card-cover {
  position: relative;
  height: 900px;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("@/assets/imgs/border.png");
}
>>>.el-card__body, .el-main {
  padding: 0;
}
.card-cover img {
  width: 100%;
  height: 100%;
}
.index {
  position: absolute;
  text-align: center;
  top: 50px;
  display: block;
  font-size: 14px;
  margin-bottom: 14px;
  height: 14px;
  font-weight: 600;
  line-height: 14px;
}
.content {
  display: block;
  font-size: 20px;
  height: 20px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
}
.active {
  background: #10579D;
  background: linear-gradient(to right, #10579D , #161130);
  border: none;
  color: #FFFFFF;
}
.title {
  padding: 160px 0 20px 0;
  width: 550px;
  height: 50px;
  font-size: 50px;
  font-weight: 600;
  color: #000000;
  line-height: 50px;
}
.more {
  margin-bottom: 80px;
  width: 128px;
  height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: #2374FC;
  line-height: 32px;
}
.more:hover {
  cursor: pointer;
}
.vidioCls {
  position: absolute;
  top: 54px;
  left: 54px;
  width: 1212px;
  height: 749px;
  border-radius: 20px;
  overflow: hidden;
}
>>> video::-webkit-media-controls-fullscreen-button {display:  none!important;}
</style>
<script setup>
</script>