<template>
  <div class="hello">
    <c-header ref="header" @scroll="scrollYElement"></c-header>
    <c-top-banner></c-top-banner>
    <c-banner></c-banner>
    <c-banner-slide ref="ppdw"></c-banner-slide>
    <c-base-scroll-y ref="qyjj"></c-base-scroll-y>
    <c-base-tabs ref="ywjj"></c-base-tabs>
    <c-base-ball ref="cpfw"></c-base-ball>
    <c-base-footer ref="footer"></c-base-footer>
  </div>
</template>

<script>
import baseHeader from './base/baseHeader.vue'
import baseBanner from './base/baseBanner.vue'
import baseBannerSlide from './base/baseBannerSlide.vue'
import baseScrollY from './base/baseScrollY.vue'
import baseTabs from './base/baseTabs.vue'
import topBanner from './base/topBanner.vue'
import baseBall from './base/baseBall.vue'
import baseFooter from './base/baseFooter.vue'

export default {
  name: 'HomePage',
  props: {
    msg: String
  },
  data() {
    return {
      scrollTopList: []
    }
  },
  components: {
    'c-header': baseHeader,
    'c-banner': baseBanner,
    'c-banner-slide': baseBannerSlide,
    'c-base-scroll-y': baseScrollY,
    'c-base-tabs': baseTabs,
    'c-top-banner': topBanner,
    'c-base-ball': baseBall,
    'c-base-footer': baseFooter
  },
  mounted() {
    this.$nextTick(() => {
      this.load()
    })
    window.addEventListener('scroll',()=> {
      this.setActive()
    })
  },
  methods: {
    load() {
      let headerH = this.$refs.header.$el.offsetTop
      this.scrollTopList.push(headerH)
      let ppdwH = this.$refs.ppdw.$el.offsetTop
      this.scrollTopList.push(ppdwH)
      let qyjjH = this.$refs.qyjj.$el.offsetTop
      this.scrollTopList.push(qyjjH)
      let ywjjH = this.$refs.ywjj.$el.offsetTop
      this.scrollTopList.push(ywjjH)
      let cpfwH = this.$refs.cpfw.$el.offsetTop
      this.scrollTopList.push(cpfwH)
    },
    setActive() {
      // 滚动条距文档顶部的距离, || 是做兼容处理的
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop == this.scrollTopList[0] || scrollTop < this.scrollTopList[1]) {
        this.$refs.header.active = 'header'
      } else if(scrollTop >= this.scrollTopList[1] && scrollTop < this.scrollTopList[2]) {
        this.$refs.header.active = 'ppdw'
      } else if(scrollTop >= this.scrollTopList[2] && scrollTop < this.scrollTopList[3]) {
        this.$refs.header.active = 'qyjj'
      } else if(scrollTop >= this.scrollTopList[3] && scrollTop < this.scrollTopList[4]) {
        this.$refs.header.active = 'ywjj'
      } else if(scrollTop >= this.scrollTopList[4]) {
        this.$refs.header.active = 'cpfw'
      }
      console.log('滚动条距文档顶部的距离',scrollTop, this.scrollTopList)
    },
    scrollYElement(val) {
      this.$refs[val].$el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
