<template>
   <el-card class="box-card">
     <el-carousel trigger="click" height="260px" arrow="always" :autoplay="false" @change="handlechange">
       <el-carousel-item class="carousel-item" v-for="(item,index) in pictures" :key="index">
         <span class="title">{{item.title}}</span>
         <span class="content" v-html="item.excerpt"></span>
         <a style="display: flex;text-decoration: none;margin-top: 28px;" :href="item.link">
           <img class="icon-img" src="@/assets/imgs/04.png"/>
           <span class="read">阅读故事</span>
         </a>
       </el-carousel-item>
     </el-carousel>
     <img v-if="pictures.length" title="这是一张图片" class="imageItem" :src="pictures[active].img"/>
   </el-card>
</template>

<script>
 import axios from "axios";

 export default {
   data() {
     return {
       urls: [],
       pictures: [],
       active: 0
     }
   },
   watch: {
     urls(newVal) {
       this.pictures = []
       newVal.forEach(item => {
         if (item._links) {
           this.pictures.push(
               {
                 img: item._links['wp:featuredmedia'] ? item._links['wp:featuredmedia'][0].href : '#',
                 title: item.title && item.title.rendered,
                 content: item.content && item.content.rendered,
                 link: item.link,
                 excerpt: item.excerpt  && item.excerpt.rendered,
               }
           )
         }
       })
       this.getPicture()
     }
   },
   created() {
     axios.get('http://143.64.40.251/index.php/wp-json/wp/v2/posts',).then(res => {
       this.urls = res.data
       console.log('请求的图片？',this.urls)
     })
   },
   methods: {
     async getPicture() {//获取图片
       for (let i = 0; i < this.pictures.length; i++) {
         await this.getImg(this.pictures[i])
       }
     },
     handlechange(val) {
       this.active = val
     },
     getImg(item) {
       if(item.img == '#') {
         return
       }
       axios.get(`${item.img}`,).then(res => {
         item.img = res.data.guid && res.data.guid.rendered
       })
     }
   }
 }
</script>
<style scoped>
.box-card {
  margin: -130px 300px 0;
  border-radius: 25px;
  position: relative;
  bottom: 0;
  z-index: 99;
  height: 260px;
  background: rgba(255,255,255,0.7);
  box-shadow: 4px 4px 16px 0px rgba(153,153,153,0.2);
  backdrop-filter: blur(20px);
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}


>>> .el-carousel__indicators--horizontal {
  display: none;
}
.carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 100px;
}
>>>.el-card__body, .el-main {
  padding: 0;
  height: 260px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.el-carousel {
  flex: 1;
}
.imageItem{
  width: 30%;
  height: 236px;
  margin: 12px;
  border-radius: 20px;
  overflow: hidden;
}
.icon-img {
  width: 32px;
  height: 32px;
}
.title {
  margin-bottom: 28px;
  white-space: nowrap;
  width: 70px;
  height: 14px;
  font-size: 14px;
  font-weight: 600;
  color: #2374FC;
  line-height: 14px;
}
.content {
  width: 60%;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 1.5;
}
.read {
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #2374FC;
  line-height: 16px;
  padding: 8px 0 0 12px;
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.custom-icon {
  color: black;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

>>>.el-carousel__arrow{
  color: black;
  background-color:transparent;
  font-size: 32px;
}
>>>.el-carousel__arrow:hover{
  background-color: transparent;
}
</style>