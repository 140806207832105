<template>
  <div id="app">
    <home-page msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HomePage from './components/HomePage.vue'
import autofit from 'autofit.js'
export default {
  name: 'App',
  components: {
    HomePage
  }
  ,
  mounted () {
    autofit.init({
      designHeight: 900,
      designWidth: 1920,
      renderDom: '#app',
      // renderDom: '.content-wrapper',
      resize: true
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh!important; /* 设置容器占据整个视口高度 */
  overflow-y: auto!important; /* 显示垂直滚动条 */
}
</style>
