<template>
  <div>
    <div class="title">开启智能新时代</div>
    <el-carousel trigger="click" :interval="2000" height="635px">
      <el-carousel-item v-for="item in urls" :key="item">
        <el-image class="image" :src="item"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

export default {
  data() {
    return {
      urls: [
        require('@/assets/imgs/lb1.png'),
        require('@/assets/imgs/lb2.png'),
        require('@/assets/imgs/lb3.png'),
        require('@/assets/imgs/lb4.png')
      ]
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.image {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.title {
  text-align: center;
  color: #000000;
  width: 418px;
  height: 50px;
  font-size: 50px;
  font-weight: 600;
  line-height: 50px;
  margin: 0 auto;
  padding: 160px 0 80px;
}

>>>.el-carousel__button {
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid  rgba(35, 116, 252, 1);
}
>>>.el-carousel__button {
  opacity: 1;
}
>>>.is-active .el-carousel__button {
  width: 120px;
  height: 20px;
  background-color: rgba(35, 116, 252, 1);
}
>>>.el-carousel {
  position: relative;
}
>>>.el-carousel__indicators {
  position: absolute;
  top: 200px;
  left: 397px;
}
>>>.el-carousel__arrow {
  display: none;
}
</style>