<template>
  <div>
    <div class="header">
      <img :src="require('@/assets/imgs/icon1.png')">
      <ul>
        <li :class="active == 'header' ? 'active' : ''" @click="handleBtnClick('header')">首页</li>
        <li :class="active == 'ppdw' ? 'active' : ''" @click="handleBtnClick('ppdw')">品牌定位</li>
        <li :class="active == 'qyjj' ? 'active' : ''" @click="handleBtnClick('qyjj')">企业简介</li>
        <li :class="active == 'ywjj' ? 'active' : ''" @click="handleBtnClick('ywjj')">业务简介</li>
        <li :class="active == 'cpfw' ? 'active' : ''" @click="handleBtnClick('cpfw')">产品服务</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 'header'
    }
  },
  methods: {
    handleBtnClick(val) {
      this.active = val
      this.$emit('scroll',val)
    }
  }
}
</script>
<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 80px;
  background: #FFFFFF;
  height: 64px;
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 999999;
  box-sizing: border-box;
  width: 100%;
}
ul {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 23px;
}
li {
  margin-right: 40px;
  font-weight: 600;
  padding-bottom: 20px;
  border-bottom: 4px solid transparent;
}
li:last-child {
  margin-right: 0;
  font-size: 16px;
}
li:hover {
  cursor: pointer;
}
.active {
  border-bottom: 4px solid rgba(35, 116, 252, 1);
  transition: all 0.3s;
}
</style>