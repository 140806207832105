import { render, staticRenderFns } from "./baseTabs.vue?vue&type=template&id=534c6553&scoped=true&"
import script from "./baseTabs.vue?vue&type=script&setup=true&lang=js&"
export * from "./baseTabs.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./baseTabs.vue?vue&type=style&index=0&id=534c6553&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534c6553",
  null
  
)

export default component.exports