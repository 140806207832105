<template>
  <div>
    <div style="display: flex; flex-direction: column; align-items: center;padding-top: 160px;">
      <div class="title">中聚智能 智领未来</div>
      <div class="title1">中庸之道，聚众成一</div>
    </div>
    <div style="margin: 0 290px">
      <el-carousel :interval="4000" type="card" height="500px" :autoplay="false">
        <el-carousel-item v-for="item in urls" :key="item">
          <img style="height: 100%;width: 100%;" :src="item">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import BScroll from 'better-scroll'
export default {
  data() {
    return {
      scroll: null,
      active: 1,
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      urls: [
        require('@/assets/imgs/x1.png'),
        require('@/assets/imgs/x2.png'),
        require('@/assets/imgs/x3.png')
      ]
    }
  },
  mounted() {
    this.initScroll()
  },
  methods: {
    initScroll() {
      this.$nextTick(() => {
        this.scroll = new BScroll('.scroll-wrapper', {
          scrollX: true,
          scrollY: false,
          click: true
        })
      })
    },
    scrollToEle(to) {
      if(to == '<') {
        this.scroll.scrollTo(200, 0,500)
      }else {
        this.scroll.scrollTo(-3*300, 0,500)
      }
    }
  }
}
</script>

<style scoped>
.scroll-wrapper {
  position: relative;
  white-space: nowrap;
  border-radius: 5px;
  overflow: hidden;
}
.scroll-wrapper .scroll-content {
  display: inline-block;
}
.scroll-wrapper .scroll-content img {
  height: 625px;
  width: 794px;
  display: inline-block;
  padding: 0 8px 0 0;
}
.scroll-wrapper .scroll-content img:nth-child(1) {
  margin-left: 150px;
}
.title {
  width: 418px;
  height: 50px;
  font-size: 50px;
  font-weight: 600;
  color: #000000;
  line-height: 50px;
}
.title1 {
  margin-top: 20px;
  width: 288px;
  height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  line-height: 32px;
  margin-bottom: 80px;
}
.btns {
  width: 200px;
  height: 50px;
  margin: 44px 0 0 150px;
}
.btns-item {
  width: 50px;
  height: 50px;
  margin: 0 0 0 10px;
  display: inline-block;
}
.btns-item:nth-child(1) {
  background-image: url("../../assets/imgs/disl.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}
.btns-item:nth-child(2) {
  background-image: url("../../assets/imgs/disr.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.btns-item:nth-child(1):hover {
  background-image: url("../../assets/imgs/onl.png");
}
.btns-item:nth-child(2):hover {
  background-image: url("../../assets/imgs/onr.png");
}
.btns-item:nth-child(1):active {
  background-image: url("../../assets/imgs/clil.png");
}
.btns-item:nth-child(2):active {
  background-image: url("../../assets/imgs/clir.png");
}



>>>.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

>>>.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

>>>.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
>>> .is-active {
  width: 793px;
  height: 625px;
}
>>> .el-carousel__item {
  width: 660px;
  height: 500px;
  border-radius: 25px;
  overflow: hidden;
}
>>> .el-carousel__mask {
  background: rgba(0,0,0);
  opacity: 0.5;
}
>>> .el-carousel__arrow {
  display: none;
}
>>> .el-carousel__indicator {
  display: none;
}
>>>.el-carousel__container {
width: 100%;
}
</style>