<template>
  <el-carousel trigger="click" height="1016px" arrow="always" style="margin-top: 64px;">
    <el-carousel-item class="carousel-item" v-for="(item,index) in urls" :key="index">
      <div class="box-img" :style="`backgroundImage: url(${item.url})`">
        <span class="title">{{item.topTilte}}</span>
        <span class="title1">{{item.content}}</span>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
      urls: [
        {
          topTilte: '开启AI新纪元',
          content: '拥抱智能，创造无限未来',
          url: require('@/assets/imgs/topBaner1.png'),
        },
        {
          topTilte: '生产力的超级进化',
          content: '解放创造力，提高生产力',
          url: require('@/assets/imgs/topBaner2.png'),
        },
        {
          topTilte: 'AI+应用正全面渗透',
          content: '让组织效率爆炸式增长，企业变革一触即发',
          url: require('@/assets/imgs/topBaner3.png'),
        },
        {
          topTilte: '智能物种爆发，行业机会涌现',
          content: '大模型时代的智能化，将引爆所有领域迭代升',
          url: require('@/assets/imgs/topBaner4.png'),
        }
      ]
    }
  },
  methods: {
  }
}
</script>
<style scoped>

>>> .el-carousel__indicators--horizontal {
  display: none;
}
.box-img {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
>>>.el-card__body, .el-main {
  padding: 0;
  height: 260px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.el-carousel {
  flex: 1;
}
.imageItem{
  width: 420px;
  height: 236px;
  margin: 12px;
}
.title {
  height: 64px;
  font-size: 64px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 64px;
  white-space: nowrap;
}
.title1 {
  margin-top: 20px;
  height: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 20px;
}
</style>